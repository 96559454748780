import { Button, ButtonProps } from '@chakra-ui/react';
import Link from './Link';
import { Link as ContentLinkProps } from '@/types';

/* NOTE: This component should return <Button as={Link} /> which works fine, but causes warning about forwardRef passed by NextLink
 * Should be fixable, but it's low priority
 */
const ButtonLink = ({
  link: { title, altTitle, url, variant: itemVariant, ...rest },
  variant,
  ...buttonProps
}: { link: ContentLinkProps } & Omit<
  ButtonProps,
  'disabled' | 'form' | 'children'
>) => {
  return (
    <Link
      title={altTitle || title}
      textDecoration="none !important"
      display="inline-block"
      href={url}
      mb={3}
      mr={3}
      borderRadius="200px"
      {...rest}
    >
      <Button as="span" {...buttonProps} variant={itemVariant || variant}>
        {title}
      </Button>
    </Link>
  );
};

export default ButtonLink;
